import React from 'react';

const blogItems = [
    {
        image: "assets/images/thumbs/blog-two-1.png",
        description: `McDonald's is a strategic partner where we can invest with confidence. As a global leader in the fast-food sector, the company has a strong brand, efficient operations and a significant presence in key markets.`,
        docLink: "racdoc/1.pdf",
    },
    {
        image: "assets/images/thumbs/blog-two-2.png",
        description: `Burger King is an ideal strategic investment partner. With a solid brand and global presence, the company constantly innovates, adapting to market trends, which guarantees sustainable growth and a safe return.`,
        docLink: "racdoc/2.pdf",
    },
    {
        image: "assets/images/thumbs/blog-two-3.png",
        description: `100 Montaditos is a strategic partner for investment. Its unique concept and expanding global footprint position the brand well. Consistently adapting to market trends, it ensures sustainable growth and promising returns.`,
        docLink: "racdoc/3.pdf",
    },
    {
        image: "assets/images/thumbs/blog-two-4.png",
        description: `RE/MAX is a strategic partner for Real Asset Capitals, aiding in the search for prime properties to invest in. With a vast network and market expertise, RE/MAX ensures access to top-tier real estate opportunities.`,
        docLink: "racdoc/4.pdf",
    },
    {
        image: "assets/images/thumbs/blog-two-5.png",
        description: `Century 21 is a strategic partner providing support in finding investment properties. With their expert guidance and extensive network, Century 21 ensures that we secure the best real estate opportunities for our clients.`,
        docLink: "racdoc/5.pdf",
    },
    {
        image: "assets/images/thumbs/blog-two-6.png",
        description: `Era is a strategic partner in search for prime real estate investments. Real Asset Capitals with Era goes above and beyond to ensure that our portfolio is not just good, but exceptional on real estate market.`,
        docLink: "racdoc/6.pdf",
    },
    {
        image: "assets/images/thumbs/blog-two-7.png",
        description: `ZOME is a trusted strategic partner that offers valuable support to Real Asset Capitals. With their extensive network and expertise in the market, ZOME helps identify opportunities that align with our investment goals.`,
        docLink: "racdoc/7.pdf",
    },
    {
        image: "assets/images/thumbs/blog-two-8.png",
        description: `Revision Estate is a trusted strategic partner in Dubai, assisting in discovering prime real estate investments. Revision Estate ensures that each investment is carefully curated and thoroughly vetted.`,
        docLink: "racdoc/8.pdf",
    },
];

export default function Section3() {
    return (
        <section className="blog padding-y-120" style={{ fontFamily: 'outfit' }} id="partners">
            <div className="container container-two">
                <div className="section-heading style-left style-flex" style={{ marginBottom: 0 }}>
                    <div className="section-heading">
                        <span className="section-heading__subtitle">
                            <span className="text-gradient fw-semibold">PARTNERS</span>
                        </span>
                        <h2 className="section-heading__title">The Secret to Success</h2>
                        <p style={{ textAlign: 'justify' }}>
                            Collaborating with strategic partners enables access to valuable resources.
                        </p>
                    </div>
                </div>

                <div className="row gy-4">
                    {blogItems.map((item, index) => (
                        <div key={index} className="col-lg-4 col-sm-6">
                            <div className="blog-item blog-dark">
                                <div className="blog-item__thumb">
                                    <a href="#" className="blog-item__thumb-link">
                                        <img src={item.image} className="cover-img" alt={`Blog Item ${index + 1}`} />
                                    </a>
                                </div>
                                <div className="blog-item__inner">
                                    <div className="blog-item__content" style={{ paddingTop: '18px' }}>
                                        <div>
                                            {[...Array(5)].map((_, starIndex) => (
                                                <svg
                                                    key={starIndex}
                                                    style={{ fill: '#a07c49', borderRadius: '6px' , margin : "3px" }}
                                                    className="star-svg"
                                                    version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/2000/xlink"
                                                    width="21px"
                                                    height="20px"
                                                >
                                                    <path d="M0,0.054V20h21V0.054H0z M15.422,18.129l-5.264-2.768l-5.265,2.768l1.006-5.863L1.64,8.114l5.887-0.855 l2.632-5.334l2.633,5.334l5.885,0.855l-4.258,4.152L15.422,18.129z" />
                                                </svg>
                                            ))}
                                        </div>

                                        <p style={{ fontSize: '13px', textAlign: 'justify', color: 'white', marginTop: '5px' }}>
                                            {item.description}
                                        </p>
                                        
                                        <a
                                            target="_blank"
                                            href={item.docLink}
                                            className="btn d-lg-block d-none"
                                            style={{
                                                padding: '10px',
                                                fontFamily: 'outfit',
                                                border: '1px solid #987646',
                                                marginTop: '10px',
                                            }}
                                        >
                                            <span>Documentation</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
